<template>
  <v-container fluid>
    <v-card elevation="6" outlined>
      <v-card-text class="ma-0 pa-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.GARNISHGROUP"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            excel-file-name="garnish group"
            ref="garnishGroup"
            show-delete-buttons
            show-edit-buttons
            :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
            :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "GarnishGroupsComponent",
  components: {Datatable},
  data(){
    return{
      ENDPOINTS,
      datatableHeaders:[
        {
          text:'UUID',
          value:'id',
          hide:true,
        },
        {
          text: this.$t('generic.lang_name'),
          value:'name',
        },
        {
          text: this.$t('generic.lang_icon'),
          value:'icon',
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          value:'color',
        },
      ],
      excelColumns:[
        {
          label: this.$t('generic.lang_name'),
          field:'name',
        },
        {
          label: this.$t('erp.lang_warecreate_color'),
          field:'color',
        },
        {
          label: this.$t('generic.lang_icon'),
          field:'icon',
        }
      ],

    }
  },
  methods:{
    editEntry(entry) {
      this.$router.push('/erp/baseData/garnishgroup/edit/' + entry.id);
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t('erp.lang_garnishGroupDeleteHead'),
        text: this.$t('erp.lang_garnishGroupDeleteBody'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.$route.params.uuid);
          }
          this.axios.post(ENDPOINTS.ERP.GARNISHGROUP.DELETE, {
            UUIDs: idsToDelete
          }).then((res) => {
            if (res.data.STATUS === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_garnishGroupDeleted'),
                color: "success"
              });

              this.$refs.garnishGroup.getDataFromApi();
              this.$refs.garnishGroup.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {

            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
  }
}
</script>

<style scoped>

</style>